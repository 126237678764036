export const SpirEnv = {
  local: 'local',
  development: 'development',
  development2: 'development2',
  staging: 'staging',
  production: 'production'
} as const

export type SpirEnv = keyof typeof SpirEnv

export function isProduction(): boolean {
  return SpirEnv.production === process.env.VUE_APP_MODE
}

export function isStaging(): boolean {
  return SpirEnv.staging === process.env.VUE_APP_MODE
}

export function isProductionLevelEnv(): boolean {
  return isAllowedByEnv([SpirEnv.production, SpirEnv.staging])
}

export function isAllowedByEnv(allowedEnvs: SpirEnv[]): boolean {
  return allowedEnvs.some(env => env === process.env.VUE_APP_MODE)
}

export function isDevelopmentLevelEnv(): boolean {
  return isAllowedByEnv([SpirEnv.local, SpirEnv.development, SpirEnv.development2])
}

const REACT_TEAM_CREATE_PAGE_IS_OPEN = 'react_team_create_page_is_open' as const
const reactTeamCreatePageIsOpenValue = 'r-t-c-p-i-o' as const

export function isReactTeamCreatePageIsOpen(): boolean {
  return (
    isDevelopmentLevelEnv() && localStorage.getItem(REACT_TEAM_CREATE_PAGE_IS_OPEN) === reactTeamCreatePageIsOpenValue
  )
}
export function enableReactTeamCreatePage(): void {
  localStorage.setItem(REACT_TEAM_CREATE_PAGE_IS_OPEN, reactTeamCreatePageIsOpenValue)
}
export function disableReactTeamCreatePage(): void {
  localStorage.removeItem(REACT_TEAM_CREATE_PAGE_IS_OPEN)
}
