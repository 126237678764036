import { render, staticRenderFns } from "./PageheadFrame.vue?vue&type=template&id=4e091fb0&scoped=true&"
import script from "./PageheadFrame.vue?vue&type=script&lang=ts&"
export * from "./PageheadFrame.vue?vue&type=script&lang=ts&"
import style0 from "./PageheadFrame.vue?vue&type=style&index=0&id=4e091fb0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_yv3tt7biadj3kwkoqdj5bnowoq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e091fb0",
  null
  
)

export default component.exports