import { render, staticRenderFns } from "./EventModalFooterOwnerFrame.vue?vue&type=template&id=0bca3582&scoped=true&"
import script from "./EventModalFooterOwnerFrame.vue?vue&type=script&lang=js&"
export * from "./EventModalFooterOwnerFrame.vue?vue&type=script&lang=js&"
import style0 from "./EventModalFooterOwnerFrame.vue?vue&type=style&index=0&id=0bca3582&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_yv3tt7biadj3kwkoqdj5bnowoq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bca3582",
  null
  
)

export default component.exports