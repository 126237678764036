import { render, staticRenderFns } from "./EventModalFooterFrame.vue?vue&type=template&id=4253691a&scoped=true&"
import script from "./EventModalFooterFrame.vue?vue&type=script&lang=ts&"
export * from "./EventModalFooterFrame.vue?vue&type=script&lang=ts&"
import style0 from "./EventModalFooterFrame.vue?vue&type=style&index=0&id=4253691a&prod&lang=scss&"
import style1 from "./EventModalFooterFrame.vue?vue&type=style&index=1&id=4253691a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_yv3tt7biadj3kwkoqdj5bnowoq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4253691a",
  null
  
)

export default component.exports