import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=b37bcca0&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=ts&"
export * from "./Index.vue?vue&type=script&lang=ts&"
import style0 from "./Index.vue?vue&type=style&index=0&id=b37bcca0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_yv3tt7biadj3kwkoqdj5bnowoq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b37bcca0",
  null
  
)

export default component.exports